import React from "react";
import Map from "../Map";

const LocationMap = ({ styles, lat, long, zoom, id }) => {
	return (
		<article className={styles.map}>
			<Map lat={lat} long={long} zoom={zoom} id={id} />
		</article>
	);
};

export default LocationMap;
