import React, { useEffect } from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import styles from "../assets/css/location.module.scss";
import SearchResults from "../components/SearchResults";
import SearchResultsMobile from "../components/SearchResults/indexMobile.js";
import Ad from "../components/Ad";
import { Link, useIntl } from "gatsby-plugin-intl";
import TitleBody from "../components/LocationBlocks/TitleBody";
import Map from "../components/LocationBlocks/map";
import ImageTitleBody from "../components/LocationBlocks/ImageTitleBody";
import Columns from "../components/LocationBlocks/Columns";
import Spacer from "../components/LocationBlocks/Spacer";
import { centrosGet } from "../redux/lugar/actions";
import { connect } from "react-redux";
import { favoritoAdd, favoritoDelete } from "../redux/favoritos/actions";
import InfoCircles from "../components/Common/InfoCircles";

const LugarPage = ({
	pageContext,
	centros,
	centrosLoading,
	centrosGet,
	favoritoAdd,
	favoritoDelete,
}) => {
	const { lugar } = pageContext;
	const intl = useIntl();

	const locationCirclesInfo = [
		{
			label: "Estaciones",
			value: lugar.centros.length,
		},
		{
			label: "KM de pistas",
			value: lugar.pistas_total,
		},
		{
			label: "Remontadores",
			value: lugar.remontes_total,
		},
		{
			label: "Kilómetros",
			value: lugar.kilometros_total,
		},
	];

	useEffect(() => {
		if (lugar.centros && lugar.centros.length > 0) {
			const ids = [];
			lugar.centros.forEach((centro) => {
				ids.push(centro.id);
			});
			centrosGet(intl.locale, {
				ids: ids.join(","),
				include_clima: 1,
				token: localStorage.getItem("token"),
			});
		}
	}, []);
	return (
		<Layout>
			<Seo title={lugar.intl.title[intl.locale]} />

			<section className={styles.place}>
				<article
					className={styles.bgPlace}
					style={{
						backgroundImage:
							lugar.imagen && lugar.imagen.url
								? `url('${lugar.imagen.url}')`
								: null,
					}}
				>
					<div className={styles.placeWrapper}>
						<Link to="/locations" className="lineLink">
							<u>Volver a lugares</u>
						</Link>
						<a href="#resorts" className={styles.seeMore}>
							Ver estaciones de ski
						</a>
					</div>
					<div className={styles.titleWrapper}>
						<h2>
							{lugar.intl.title[intl.locale]}
							<span>{lugar.intl.subtitle[intl.locale]}</span>
						</h2>
					</div>
				</article>

				<article className={styles.placeInfo}>
					<InfoCircles items={locationCirclesInfo} />
				</article>

				{/* bloques */}

				{lugar.lugar_bloques.map((bloque, index) => {
					switch (bloque.type) {
						case "rm_title_body":
							return (
								<TitleBody
									key={index}
									styles={styles}
									title={bloque.intl.title[intl.locale]}
									body={bloque.intl.body[intl.locale]}
								/>
							);
						case "rm_map":
							return (
								<Map
									key={index}
									styles={styles}
									lat={bloque.lat}
									long={bloque.long}
									zoom={bloque.zoom}
									id={`lugarId${lugar.id}bloque${index}`}
								/>
							);
						case "rm_image_title_body":
							return (
								<ImageTitleBody
									key={index}
									styles={styles}
									image={bloque.imagen.url}
									title={bloque.intl.title[intl.locale]}
									body={bloque.intl.body[intl.locale]}
									align={bloque.align}
								/>
							);
						case "rm_title_body_columns":
							return (
								<Columns
									key={index}
									styles={styles}
									columns={bloque.columns}
								/>
							);
						case "rm_spacer":
							return <Spacer key={index} styles={styles} />;
						default:
							return null;
					}
				})}

				<a id="resorts" />

				<article className={styles.stations}>
					<div className={styles.stationsWrapper}>
						<div className={styles.mainContent}>
							<h3>Estaciones y puntos de acceso de {lugar.title}</h3>
							<div className={styles.searchResultsWrapper}>
								<SearchResults
									centros={centros}
									showMoreResults={false}
									loading={centrosLoading}
									onFavoritoAdd={(id) => favoritoAdd(intl.locale, id)}
									onFavoritoDelete={(id) =>
										favoritoDelete(intl.locale, id)
									}
								/>
								<SearchResultsMobile
									centros={centros}
									onMoreResults={false}
									loading={centrosLoading}
									onFavoritoAdd={(id) => favoritoAdd(intl.locale, id)}
									onFavoritoDelete={(id) =>
										favoritoDelete(intl.locale, id)
									}
								/>
							</div>

							{lugar.ad && (
								<div className={styles.adsWrapper}>
									<Ad ad={lugar.ad} />
								</div>
							)}
						</div>
					</div>
				</article>

				<article className={styles.placeNews}>
					<div className={styles.presentText}>
						<h3>Noticias de {lugar.title}</h3>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Faucibus eu justo dolor nunc facilisis. Molestie hac libero
							aliquam lobortis.
						</p>
					</div>
					<div className={styles.slider}>
						{/* Acá iría un component */}
						<div className={styles.item}>
							<div className={styles.itemWrapper}>
								<img src="/station/actualidad-img.jpg" alt="" />
								<div className={styles.text}>
									<small>Mayo 14</small>
									<p>
										Andorra: 70 temporeros argentinos más vuelven a
										casa
									</p>
									<a className="lineLink">
										<u>Leer más</u>
									</a>
								</div>
							</div>
						</div>
						<div className={styles.item}>
							<div className={styles.itemWrapper}>
								<img src="/station/actualidad-img.jpg" alt="" />
								<div className={styles.text}>
									<small>Mayo 14</small>
									<p>
										21 temporeros chilenos vuelven a su país después
										de dos años de confinamiento bla bla bla texto
										lorem ipsum etc etc etc
									</p>
									<a className="lineLink">
										<u>Leer más</u>
									</a>
								</div>
							</div>
						</div>
						<div className={styles.item}>
							<div className={styles.itemWrapper}>
								<img src="/station/actualidad-img.jpg" alt="" />
								<div className={styles.text}>
									<small>Mayo 14</small>
									<p>
										Andorra: 70 temporeros argentinos más vuelven a
										casa
									</p>
									<a className="lineLink">
										<u>Leer más</u>
									</a>
								</div>
							</div>
						</div>
						<div className={styles.item}>
							<div className={styles.itemWrapper}>
								<img src="/station/actualidad-img.jpg" alt="" />
								<div className={styles.text}>
									<small>Mayo 14</small>
									<p>
										Andorra: 70 temporeros argentinos más vuelven a
										casa
									</p>
									<a className="lineLink">
										<u>Leer más</u>
									</a>
								</div>
							</div>
						</div>
					</div>
				</article>
			</section>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		centros: state.lugar.centros,
		centrosLoading: state.centros.loading,
	};
};

const mapDispatchToProps = {
	centrosGet,
	favoritoAdd,
	favoritoDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(LugarPage);
