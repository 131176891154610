import React from "react";
import PropTypes from "prop-types";

const ImageTitleBody = ({ styles, image, title, body, align }) => {
	return (
		<article className={styles.placeDetailsPhotos}>
			{align === "left" && (
				<>
					{image && (
						<div className={styles.img}>
							<img src={image} alt={title} />
						</div>
					)}
					<div className={styles.text}>
						{title && <h3>{title}</h3>}
						{body && <p dangerouslySetInnerHTML={{ __html: body }} />}
					</div>
				</>
			)}
			{align === "right" && (
				<>
					<div className={styles.text}>
						{title && <h3>{title}</h3>}
						{body && <p dangerouslySetInnerHTML={{ __html: body }} />}
					</div>
					{image && (
						<div className={styles.img}>
							<img src={image} alt={title} />
						</div>
					)}
				</>
			)}
		</article>
	);
};

ImageTitleBody.propTypes = {
	styles: PropTypes.object.isRequired,
	image: PropTypes.string.isRequired,
	title: PropTypes.string,
	body: PropTypes.string,
	align: PropTypes.oneOf(["left", "right"]),
};

ImageTitleBody.defaultProps = {
	styles: {},
	image: null,
	title: null,
	body: null,
	align: "left",
};

export default ImageTitleBody;
