import React from "react";

const TitleBody = ({ styles, title, body }) => {
	return (
		<article className={styles.placeDetails}>
			{title && <h3>{title}</h3>}
			{body && <p dangerouslySetInnerHTML={{ __html: body }} />}
		</article>
	);
};

export default TitleBody;
