import { useIntl } from "gatsby-plugin-intl";
import React from "react";

const Columns = ({ styles, columns }) => {
	const intl = useIntl();
	return (
		<article className={styles.placeLastDetails}>
			<div className={styles.lastDetailsWrapper}>
				<ul>
					{columns.map((column, index) => {
						return (
							<li key={index}>
								{column.intl.title[intl.locale] && (
									<h3>{column.intl.title[intl.locale]}</h3>
								)}
								{column.intl.body[intl.locale] && (
									<p
										dangerouslySetInnerHTML={{
											__html: column.intl.body[intl.locale],
										}}
									/>
								)}
							</li>
						);
					})}
				</ul>
			</div>
		</article>
	);
}

export default Columns;